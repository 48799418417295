<div class="footer back-dark pb-sm-3">
    <div class="container">
 		<div class="footer-info text-center-xs text-center-sm clearfix">
			<div class="footer-social">
				<a href="https://www.facebook.com/midamericafistsinaction" target="_blank"><i class="fab fa-facebook-square"></i></a>
				<a href="https://twitter.com/mafiablackhole" target="_blank"><i class="fab fa-twitter"></i></a>
			</div>
		</div>       
        <div class="footer-top clearfix">
			<div class="footer-nav float-lg-left text-center">
				<a routerLink="home">Home</a>
				<a routerLink="events">Events</a>
				<a routerLink="register">Register</a>
				<a routerLink="join">Join</a>
				<a routerLink="info">Info</a>
			</div>
        </div>     
		<div class="footer-bottom text-center">
            <p class="inline-block">Copyright &copy; 2025 Mid America Fists In Action. All rights reserved.</p>
        </div>
    </div>
</div>