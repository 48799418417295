<div class="container">
    <div class="row no-gutters align-items-center h-100">
        <div class="col-xs-12 col-sm-offset-2 col-sm-8">
            <h3 class="section-head">Join The Club</h3>
            <br>
            <p class="text">	
                <strong><a href="https://midamericafistsinaction.wildapricot.org/join-us" target="_blank" class="btn mafia-btn-otl mb-3">Membership Application and Renewal</a></strong>													
            </p>
            <p class="text">
                Membership in MAFIA is open to those who identify as male, are 21 years of age or older, and who have demonstrated an interest and proficiency at Fisting or Handballing. Although membership in MAFIA is limited to self-identified males, we do not discriminate on the basis of race, religion, ethnicity, physical ability, HIV status, partnership/marital status, or other protected categories.
            </p>
            <p class="text">
                MAFIA members are required to comply with the organization's By-Laws and House Rules. Sanctions for violations of the rules and policies of MAFIA are defined in the By-Laws. The MAFIA membership list is confidential and is not disclosed to non-members.
            </p>
            <p class="text">
                To apply for full membership, you must be sponsored by two current members in good standing. <b>MAFIA club membership is NOT required to register to attend our parties.</b>
            </p>
            <p class="text">	
                Our Membership Secretary will report the names of applicants and renewals at the next regular MAFIA Board of Directors meeting to be voted on for provisional membership. At the following Board meeting, any comments received concerning the applicant will be presented and discussed. The Board will then vote to grant full membership for provisional members. Applicants will be notified by email of the Board vote results.
            </p>
        </div>
    </div>
</div>
